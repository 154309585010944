﻿@import (reference) "../../../Cider.Framework/Assets/Less/bootstrap-references.less";
@imageWidth: 118px;

//Mixins
.inlineFlex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex; 
}

img.thumbnail{
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
}

.rightArrow, .leftArrow  {
    z-index: 901;
    cursor: pointer;
    width:7%;
    position:relative;
}

.arrow-icon {
    top:38%;
    position:absolute;
    width:100%;
    text-align:center;
}

.invisible {
    opacity: 0;
}

.product-cell {
    -webkit-break-inside: avoid-column;
    break-inside: avoid-column;
    display: inline-block;
    column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;

    div {
        .itemLink {
            color: #555555;
        }
    }
    padding: 14px; 
    background: transparent;

    .form-control.mini{
        padding: 1px;
        padding-right: 0;
        height: 23px;
        line-height: 1;
        font-size: 11.5px;
    }

    &:hover{
        .product-thumbnail-link{
            opacity: .75;
        }
    }
}

.item-picker .add-to-cart-container {
    width:@imageWidth;

    .product-narrative{
        padding-bottom:0;

        p{
            margin-bottom:0;
        }
    }
}

.item-picker {
    clear: both;
    padding-top: 0;
    overflow: hidden;
    width: 87%;
    z-index: 900;
}



.item-picker.ie9 {
    width: auto;
}

    
.product-title {
    margin-top:3px;;
    text-align: left;
    float:left;
    font-size: 14px;
    color: #111111;
    padding:3px 0 0 2px;
}

.product-scroller-container {
    .inlineFlex;
    width: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    
    .product-title {
        margin-top:0;
        font-size: 12px;
    }

    .add-cart-to-container, .product-thumbnail-link, .product-title{
            width:@imageWidth;
    }
}

.product-list-table {
    margin: 0;
    .inlineFlex;
}

.widget-featured-products-widget, .widget-recent-products-widget, .widget-upsell-widget, .widget-purchased-with-widget, .widget-related-products-widget, .widget-product-family-widget {
     h3 {
        display: none;
    }
}

.product-scroller {
    padding-bottom: 10px;
}

.product-list-widget-title {
    h3 {
        font-size: 15px;
        clear: both;
        display: block;
        margin: 0;
        margin-left: 7%;
    }
}

.rotating-deal-row{
    .make-row();
}

.rotating-deal{
    
    .make-xs-column(12);

    .product-cell{
        display:block;
        margin-left:10px;

        .product-thumbnail-link{
            width:40%;
            float:left;

            img{
                width:90px;
                margin-left:10px;
            }
        }
        .add-to-cart-container{
            width:50%;
            float:left;
            margin-left:10px;
        }

        .product-title{
            font-size:18px;
            margin:0;
        }

        .form-control.mini{
            height:auto;
            padding:3px;
            font-size:12.5px;
        }
        .product-price.mini{
            font-size:20px;
            margin-bottom:5px;
        }

        .add-to-cart.mini-add-to-cart:after {
            font-size:28px;
        }
    }
    .rotating-deal-announce{
        text-align:center;
        p{
            margin:0;
        }
    }
}

.add-to-cart.mini-add-to-cart {
    color: #555555;
    background: transparent;
    padding: 1px;
    border: none;
    box-shadow: none;
    margin: 0;
    float: left;

    &:after {
        font-family: 'FontAwesome';
        content: '\f07a';
        font-size: 1.333333em;
        margin: -3px 0 -5px 3px;
        float: left;
    }
}

.product-price, .cart-product-price {
    font-size: 20px;
    font-weight: bold;

    &.mini {
        font-size:14px;
        float:left; 
        padding-bottom:0;
    }
}

.productListPrice {
    font-weight: normal;
    font-size: 14px;
    padding: 2px;
    text-align: right;

    &.mini{
        font-size:12px;
        padding:0;
        float:left;
        padding-bottom: 2px;
    }
}

.product-list-price-equal {
    .productListPrice;
}

.product-list-price-strike-through {
    .productListPrice;
}

@media(max-width:1100px) {
    .rotating-deal{
        .product-cell{

            .product-thumbnail-link{
                img{
                    width:70px;
                }
            }
        }
    }
}

@media(max-width:@screen-sm-min) {
    .rightArrow, .leftArrow  {
        width: 10%;
        padding-top: 25%;
    }
}

@media(max-width:400px) {
    .rightArrow, .leftArrow {
        width: 13%;
        padding-top: 50%;
    }
}

.product-browse-fields {
    .make-row();
    padding-top:10px;
}

.taxonomy-level, .product-browse-button {
    .make-sm-column(3);
}